<template>
  <el-dialog title="授权" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form label-width="160px">
      <el-form-item>
        <el-tree :data="data" show-checkbox ref="tree" node-key="id" :default-checked-keys="defaultChecked" :props="defaultProps"> </el-tree>
        <div style="height: 24px;"></div>
        <el-button type="primary" @click="onSubmit">授权</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { treeList, roleAuthorize, roleDetail } from '@/api/businessManage/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: {},
    isDisable: false,
    machineType: []
  },
  components: {},
  computed: {},
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'subFunctions',
        label: 'name'
      },
      defaultChecked: []
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        console.log('???');
        this.getList();

        this.getDetail();
      }
    }
  },
  methods: {
    getList() {
      treeList().then(e => {
        this.data = e;
      }).catch(error => {

      });
    },
    getDetail() {
      roleDetail({ id: this.currentId.id }).then(e => {
        this.defaultChecked = e.currentFunctionIDs;
      }).catch(error => {

      });
    },

    closeMe(done) {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      console.log(this.$refs.tree.getCheckedKeys());
      let list = this.$refs.tree.getCheckedKeys();
      roleAuthorize({ roleId: this.currentId.id, functionIds: list }).then(e => {
        this.$message({
          message: '授权成功',
          type: 'success'
        });
        this.closeMe();
        this.updateList();
      }).catch(error => {

      });

      // let formData = _.cloneDeep(this.form);
      // if (this.isEdit) {
      //   roleUpdate(formData).then(e => {
      //     this.$message({
      //       message: '修改成功',
      //       type: 'success'
      //     });
      //     this.closeMe();
      //     this.updateList();
      //   });
      // } else {
      //   roleAdd(formData).then(e => {
      //     this.$message({
      //       message: '创建成功',
      //       type: 'success'
      //     });
      //     this.closeMe();
      //     this.updateList();
      //   });
      // }
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss"></style>
