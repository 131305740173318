<template>
  <el-dialog :title="title" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form :rules="rules" ref="ruleForm" :model="form" label-width="160px" :disabled="isDisable">
      <el-form-item label="用户编号" prop="userCode">
        <el-input v-model="form.userCode"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="用户密码" prop="password">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="用户手机号" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="用户所属部门" prop="departmentId">
        <el-select v-model="form.departmentId" placeholder="请选择" :disabled="isDisable" @change="onOptionChange">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { userAdd, userUpdate, organizationList } from '@/api/businessManage/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: {},
    isDisable: false,
    machineType: []
  },
  components: {},
  computed: {
    title() {
      let state = '新建';
      if (this.isEdit) {
        state = '编辑';
      }
      return state;
    }
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        departmentId: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      roleList: [],
      form: {
        name: '',
        password: '',
        departmentId: '',
        userCode: '',
        phone: ''
      }
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.form = {
          name: '',
          password: '',
          departmentId: '',
          userCode: '',
          phone: ''
        };
        this.getList();
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    getList() {
      organizationList()
        .then((e) => {
          this.roleList = e;
        })
        .catch((error) => {});
    },
    getDetail() {
      this.form = _.cloneDeep(this.currentId);
    },

    closeMe(done) {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((val) => {
        if (!val) return;

        let formData = _.cloneDeep(this.form);
        if (this.isEdit) {
          userUpdate(formData)
            .then((e) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else {
          userAdd(formData)
            .then((e) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        }
      });
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss"></style>
