<template>
  <el-dialog :title="title" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form :rules="rules" ref="ruleForm" :model="form" label-width="160px" :disabled="isDisable">
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="角色描述" prop="descriptions">
        <el-input type="textarea" autosize v-model="form.descriptions"></el-input>
      </el-form-item>
      <el-form-item label="角色编号" prop="code">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { roleAdd, roleUpdate } from '@/api/businessManage/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: {},
    isDisable: false,
    machineType: []
  },
  components: {},
  computed: {
    title() {
      let state = '新建';
      if (this.isEdit) {
        state = '编辑';
      }
      return state;
    }
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        descriptions: [{ required: true, message: '请输入角色描述', trigger: 'blur' }]
      },
      form: {
        name: '',
        code: '',
        descriptions: ''
      }
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.form = {
          name: '',
          code: '',
          descriptions: ''
        };
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    getDetail() {
      this.form = _.cloneDeep(this.currentId);
    },

    closeMe(done) {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      this.$refs['ruleForm'].validate(val => {
        if (!val) return;

        let formData = _.cloneDeep(this.form);
        if (this.isEdit) {
          roleUpdate(formData).then(e => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.closeMe();
            this.updateList();
          }).catch(error => {

      });
        } else {
          roleAdd(formData).then(e => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.closeMe();
            this.updateList();
          });
        }
      }).catch(error => {

      });
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss"></style>
