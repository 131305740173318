<template>
  <el-dialog title="授权" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form label-width="160px">
      <el-form-item>
        <el-form-item label="关联角色">
          <el-select v-model="roleIds" placeholder="请选择" multiple collapse-tags>
            <el-option v-for="item in data" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <div style="height: 24px;"></div>
        <el-button type="primary" @click="onSubmit">授权</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { roleAll, userAuthorize, roleDetail } from '@/api/businessManage/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: {},
    isDisable: false,
    machineType: []
  },
  components: {},
  computed: {},
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'subFunctions',
        label: 'name'
      },
      roleIds: [],
      defaultChecked: []
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList();
        this.roleIds = [];
        if (this.currentId && this.currentId.secRoleVOS) {
          this.roleIds = this.currentId.secRoleVOS.map(e => {
            return e.id;
          });
        }
      }
    }
  },
  methods: {
    getList() {
      roleAll().then(e => {
        this.data = e;
      }).catch(error => {

      });
    },

    closeMe(done) {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      userAuthorize({ userId: this.currentId.id, roleIds: this.roleIds }).then(e => {
        this.$message({
          message: '授权成功',
          type: 'success'
        });
        this.closeMe();
        this.updateList();
      }).catch(error => {

      });

      // let formData = _.cloneDeep(this.form);
      // if (this.isEdit) {
      //   roleUpdate(formData).then(e => {
      //     this.$message({
      //       message: '修改成功',
      //       type: 'success'
      //     });
      //     this.closeMe();
      //     this.updateList();
      //   });
      // } else {
      //   roleAdd(formData).then(e => {
      //     this.$message({
      //       message: '创建成功',
      //       type: 'success'
      //     });
      //     this.closeMe();
      //     this.updateList();
      //   });
      // }
    },

    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss"></style>
